/* You can add global styles to this file, and also import other style files */

:root {
  --ci-header: linear-gradient(180deg, #004b00 0%, #1f961b 100%);
  --ci-gradient: linear-gradient(180deg, #004b00 0%, #1f961b 100%);
  --ci-bg: linear-gradient(58deg, #d32800, #ef443c, #f4764d, #da3e37);
  --color-darkgreen: #004b00;
  --color-green: #1f961b;
  --color-lightgreen: #DDFBDD;
  --color-dark: #212121;
  --font-heading: 'Prompt', sans-serif;
  --font-prompt-bold: 600;
  --font-content: 'Sarabun', sans-serif;
  --font-sarabun-bold: 700;

}

#nprogress .bar{
  background: var(--color-green);
}
#nprogress .spinner-icon{
  border-top-color: var(--color-green);;
  border-left-color: var(--color-green);;
}
#qrcode img{
  margin: auto!important;
}

#crisp-chatbox{
  z-index: 990;
}

.add_to_cart_status{
  top: 30%;
}

html,
body{
  margin: 0;
  padding: 0;
}

@media (min-width: 1200px) {
  .container{
    max-width: 1300px;
  }
}


*:focus{
  outline: none;
}





/* =========================================================================== */
/* ================================ TYPOGRAPHY ================================*/
/* =========================================================================== */
  html,
  body{
    font-family: var(--font-content);
    font-weight: 400;
    color: var(--color-dark);
  }
  h1, h2, h3, h4, h5, h6{
    font-family: var(--font-heading);
    font-weight: var(--font-prompt-bold);
  }
  button,
  .product-title,
  .product-price,
  .ant-collapse-header{
    font-family: var(--font-heading);
  }
  .text-dark{
    color: var(--color-dark)
  }
  .text-danger{
    color: #ff7875;
  }
  .font-weight-normal{
    font-weight: 400;
  }
  .font-sarabun-bold{
    font-weight: 700;
  }
  .font-prompt-bold{
    font-family: var(--font-heading);
    font-weight: 600;
  }
  .font-prompt{
    font-family: var(--font-heading);
  }
  .text-link{
    font-family: var(--font-heading);
    color: var(--color-green);
  }
  .text-link:hover{
    color: var(--color-darkgreen);
  }
  .text-link-default{
    font-family: var(--font-heading);
    color: rgba(0, 0, 0, 0.45);
  }
  .text-link-default:hover{
    color: rgba(0, 0, 0, 0.65);
  }
  .text-muted{
    color: #888;
  }
  .title{
    font-size: 1.75rem;
  }
  .sub-title{
    font-size: 1.25rem;
  }
  .font-size-12{
    font-size: 12px;
  }
  .font-size-14{
    font-size: 14px;
  }
  .font-size-16{
    font-size: 16px;
  }
  .font-size-18{
    font-size: 18px;
  }
  .font-size-20{
    font-size: 20px;
  }
  @media (max-width: 767.98px){
    .title {
        font-size: 1.25rem;
    }
    .sub-title {
      font-size: 1rem;
  }
  }
  .text-black-85{
    color: rgba(0, 0, 0, 0.85);
  }
  .text-black-65{
    color: rgba(0, 0, 0, 0.65);
  }
  .text-black-45{
    color: rgba(0, 0, 0, 0.45);
  }
  .text-black-25{
    color: rgba(0, 0, 0, 0.25);
  }
  .text-white{
    color: #fff;
  }
  .text-white-85{
    color: rgba(255, 255, 255, 0.85);
  }
  .text-white-65{
    color: rgba(255, 255, 255, 0.65);
  }
  .text-white-45{
    color: rgba(255, 255, 255, 0.45);
  }
  .text-white-25{
    color: rgba(255, 255, 255, 0.25);
  }
  .text-darkgreen{
    color: var(--color-darkgreen)
  }
  .text-green{
    color: var(--color-green)
  }
  .text-lightgreen{
    color: var(--color-lightgreen)
  }
/* =========================================================================== */





/* =========================================================================== */
/* ================================ BOOTSTRAP =================================*/
/* =========================================================================== */
  .w-100{
    width: 100%;
  }
  .btn-secondary{
    color: #fff;
    background-color: #888;
    border-color: #888;
  }
  .form-group{
    margin-bottom: 12px;
  }
  .form-lable {
    font-family: var(--font-heading);
    /* font-weight: var(--font-prompt-bold); */
    color: #525252;
    margin-bottom: 2px;
  }
  .text-left{
    text-align: left!important;
  }
  .text-right{
    text-align: right!important;
  }
  .text-center{
    text-align: center!important;
  }
  @media (min-width: 768px) {
    .text-md-left{
      text-align: left!important;
    }
    .text-md-right{
      text-align: right!important;
    }
    .text-md-center{
      text-align: center!important;
    }
  }
  @media (min-width: 992px) {
    .text-lg-left{
      text-align: left!important;
    }
    .text-lg-right{
      text-align: right!important;
    }
    .text-lg-center{
      text-align: center!important;
    }
  }
  @media (min-width: 1200px){
    .text-xl-left{
      text-align: left!important;
    }
    .text-xl-right{
      text-align: right!important;
    }
    .text-xl-center{
      text-align: center!important;
    }
    .mb-lg-0{
      margin-bottom: 0!important;
    }
    .pl-lg-0{
      padding-left: 0!important;
    }
  }
  .list-none > li{
    list-style: none;
  }
  .list-circle > li{
      list-style: circle;
  }
  .list-roman > li{
      list-style: upper-roman;
  }
  .list-number > li{
      list-style: decimal;
  }
  .img-fluid{
    max-width: 100%;
  }
  .label{
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    margin-bottom: 0.25rem;
    opacity: 0.8;
  }
  .mx-row-2{
    margin-left: -8px;
    margin-right: -8px;
  }
  .h-100{
    height: 100%;
  }
/* =========================================================================== */





/* =========================================================================== */
/* ================================ OVERRIDE ================================= */
/* =========================================================================== */



  .ant-btn-disabled,
  .ant-btn.disabled,
  .ant-btn[disabled],
  .ant-btn-disabled:hover,
  .ant-btn.disabled:hover,
  .ant-btn[disabled]:hover,
  .ant-btn-disabled:focus,
  .ant-btn.disabled:focus,
  .ant-btn[disabled]:focus,
  .ant-btn-disabled:active,
  .ant-btn.disabled:active,
  .ant-btn[disabled]:active,
  .ant-btn-disabled.active,
  .ant-btn.disabled.active,
  .ant-btn[disabled].active{
    opacity: 0.6;
    pointer-events: none;
  }
  .ant-input-lg{
    height: 40px;
    line-height: 40px;
  }
  .nav-trigger .ant-btn-background-ghost{
    border-color: transparent;
  }
  .nav-trigger .ant-btn-icon-only.ant-btn-lg{
    font-size: 28px;
    line-height: 40px;
    padding: 0;
  }
  .nav-trigger .ant-btn-icon-only.ant-btn-lg i{
    font-size: 28px;
  }
  .nav-trigger .ant-btn:hover,
  .nav-trigger .ant-btn:focus{
    color: #fff;
  }
  .ant-drawer-body{
    padding: 15px;
  }
  .drawer-sm{
    width: 95%!important;
    max-width: 340px!important;
  }
  .drawer-md{
    width: 95%!important;
    max-width: 600px!important;
  }
  .drawer-nav{
    width: 95%!important;
    max-width: 300px!important;
  }
  .drawer-nav .ant-drawer-body{
    padding: 0;
  }
  app-cart .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
    left: 0px;
  }
  app-cart .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding-left: 20px;
  }
  app-cart .ant-collapse-header{
    font-weight: 600;
  }
  app-cart .ant-collapse-content > .ant-collapse-content-box{
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .widget-search .ant-input-group .ant-input,
  .widget-search .ant-input-group .ant-input:hover,
  .widget-search .ant-input-group .ant-input:focus{
    border: solid 1px #fff;
  }
  .widget-search button,
  .widget-search button:hover,
  .widget-search button:focus{
    border: solid 1px #fff;
  }
  .ant-tabs-nav .ant-tabs-tab{
    font-family: 'Prompt', sans-serif;
    font-weight: 400;
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
    background: unset;
  }
  .ant-table-thead{
    font-family: 'Prompt', sans-serif;
  }
  .ant-table-thead > tr > th{
    font-weight: var(--font-prompt-bold);
    color: var(--color-dark);
  }
  .ant-upload.ant-upload-select-picture-card{
    border-radius: 50%;
    overflow: hidden;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload{
    padding: 0!important;
  }

  .ant-upload.ant-upload-select img{
    width: 100px;
    height: 100px;
  }
  .ant-form-explain, .ant-form-extra{
    line-height: 0;
  }
  .ant-tabs-top-bar.sticky{
    position: fixed;
    width: 100%;
    top: 65px;
    left: 0;
  }
  .ant-card{
    color: var(--color-dark);
  }
  .ant-tag{
    font-family: var(--font-heading);
  }
  .owl-carousel .owl-nav{
    position: absolute;
    width: 100%;
    top: 30%;
    left: 0;
  }
  .owl-theme .owl-dots{
    position: absolute;
    width: 100%;
    bottom: 10px;
  }
  app-product-detail .owl-theme .owl-dots{
    bottom: -10px;
  }
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-prev{
    width: 30px;
    height: 34px;
    background-color: #fff;
    color: #d5d5d5;
    border: 1px solid #d5d5d5;
    padding: 0;
    text-align: center;
    opacity: 0.6;
    font-size: 20px;
  }
  .owl-carousel .owl-nav button.owl-next span,
  .owl-carousel .owl-nav button.owl-prev span{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .owl-theme .owl-nav [class*=owl-]:hover {
    background: #fff;
    color: #d5d5d5;
    text-decoration: none;
    opacity: 1;
  }
  .owl-carousel .owl-nav button.owl-prev{
    position: absolute;
    left: -15px;
  }
  .owl-carousel .owl-nav button.owl-next{
    position: absolute;
    right: -15px;
  }
  .widget-product-filter .ant-tag-checkable{
    border: 1px solid #d9d9d9;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
  }
  .widget-product-filter .ant-tag-checkable-checked{
    border-color: var(--color-green);
  }
  .widget-product-filter .ant-tag-checkable .icon-check{
    display: none;
  }
  .widget-product-filter .ant-tag-checkable-checked .icon-check{
    display: inline-block;
  }
  
  app-products .ant-tabs-bar{
    margin-bottom: 0
  }
  app-products .widget-product-filter .ant-tag, 
  app-market-product-category .widget-product-filter .ant-tag {
    cursor: pointer;
    font-family: 'Prompt', sans-serif;
    height: 28px;
    line-height: 28px;
  }
  app-products .widget-product-filter .ant-tag-checkable-checked,
  app-market-product-category .widget-product-filter .ant-tag-checkable-checked {
    background-color: var(--color-green);
  }
  app-market-banner .owl-carousel .owl-nav{
    top: 40%;
  }
  app-market-banner .owl-carousel .owl-nav button.owl-prev{
    left: 20px;
  }
  app-market-banner .owl-carousel .owl-nav button.owl-next{
    right: 20px;
  }
  @media (max-width: 767.98px) {
    .owl-carousel .owl-nav,
    app-market-banner .owl-carousel .owl-nav{
      top: 20%;
    }
    app-market-banner .owl-theme .owl-dots{
      bottom: 2px;
    }
  }

  .ant-table.ant-table-bordered .ant-table-container {
    border: none;
    border-right: 0;
    border-bottom: 0;
  }

  .widget-category.sticky .ant-tabs-bar{
    position: fixed;
    width: 100%;
    left: 0;
    top: 90px;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
  }

  /* Container */
  @media (min-width: 768px){
    .widget-category.sticky .ant-tabs-bar .ant-tabs-nav-container{
      max-width: 720px;
      margin: auto;
    }
  }
  @media (min-width: 992px){
    .widget-category.sticky .ant-tabs-bar .ant-tabs-nav-container{
      max-width: 960px;
      margin: auto;
    }
  }
  @media (min-width: 1200px) {
    .widget-category.sticky .ant-tabs-bar .ant-tabs-nav-container{
      max-width: 1300px;
      margin: auto;
    }
  }


  @media (max-width: 767.98px){
    .widget-category.sticky .ant-tabs-bar{
      top: 59px;
      padding: 0 15px;
    }
  }

.cdk-overlay-container .ant-drawer-content-wrapper{
  max-width: 100%;
}

.ant-tag.mr-0{
  margin-right: 0;
}
.yl-select-search .ant-select-item,
.yl-select-account .ant-dropdown-menu-item{
  padding: 8px 16px;
  font-family: 'Prompt', sans-serif;
}
.yl-select-account .ant-dropdown-menu-item > .anticon:first-child{
  font-size: 16px;
  margin-right: 4px;
}
.yl-select-account .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: var(--color-lightgreen);
  color: var(--color-darkgreen);
}

.modal-sm .ant-modal{
  width: 90%!important;
  max-width: 480px;
}

.ant-rate-disabled .ant-rate-star:not(:last-child){
  margin-right: 4px;
}
.ant-rate.ant-rate-disabled{
  font-size: 16px;
}
.cs-pointer .ant-rate-disabled .ant-rate-star{
  cursor: pointer;
}
/* =========================================================================== */


.modal-footer{
  margin-top: 20px;
  text-align: right;
  border-top: solid 1px #eee;
  padding-top: 20px;
}

.ant-drawer-wrapper-body{
  overflow: hidden!important;
}

.ant-drawer-body{
  height: 85vh;
  overflow: auto;
  padding-bottom: 60px;
}
@media (max-width: 767.98px) {
  .ant-drawer-body .content-scroll{
    padding-bottom: 60px;
  }
}

.ant-drawer-wrapper-body .footer{
  z-index: 99;
}

.cs-pointer{
  cursor: pointer;
}
.cs-reset{
  cursor: inherit;
}
.rounded{
  border-radius: .25rem;
}
.border{
  border: 1px solid #f0f0f0;
}
.border-bottom{
  border-bottom: 1px solid #f0f0f0;
}
/* =========================================================================== */
/* ================================== SELLER ================================= */
/* =========================================================================== */
  app-seller .ant-menu-dark .ant-menu-item{
    font-family: var(--font-heading);
  }
  app-seller .logo{
    max-width: 180px;
  }
  app-seller .logo img{
    max-width: 100%;
  }
  app-seller .wrap-page {
    padding: 12px;
    min-height: 88vh;
    border-radius: 5px;
    margin: 16px;
  }
  @media (max-width: 1199.98px) {
    app-seller .wrap-page{
      padding: 15px;
      margin: 0;
    }
    app-seller h1{
      font-size: 1.5rem;
    }
  }
  
  app-system .wrap-page {
    padding: 12px;
    min-height: 88vh;
    border-radius: 5px;
    margin: 16px;
  }
  app-seller ._table_shiupping_rate table{
    border: none!important;
  }
  @-webkit-keyframes market-menu-bg {
    0% {
      background-position: 83% 0%
    }

    50% {
      background-position: 18% 100%
    }

    100% {
      background-position: 83% 0%
    }
  }
  @-moz-keyframes market-menu-bg {
    0% {
      background-position: 83% 0%
    }

    50% {
      background-position: 18% 100%
    }

    100% {
      background-position: 83% 0%
    }
  }
  @-o-keyframes market-menu-bg {
    0% {
      background-position: 83% 0%
    }

    50% {
      background-position: 18% 100%
    }

    100% {
      background-position: 83% 0%
    }
  }
  @keyframes market-menu-bg {
    0% {
      background-position: 83% 0%
    }

    50% {
      background-position: 18% 100%
    }

    100% {
      background-position: 83% 0%
    }
  }
  app-seller .card-box {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0 0 16px;
    -webkit-transition: all .2s;
    transition: all .2s;
    border-radius: 5px;
    background: #fff;
    border: solid 1px #e4e4e4;
  }
  app-seller .card-box-body {
    -webkit-box-align: center;
    align-items: center;
    display: block;
    padding: 16px;
  }
  app-seller .card-box-body .ant-list-item-meta-content {
    padding-top: 10px;
  }
  app-seller .card-box .title {
    text-transform: uppercase;
  }
  app-seller .card-box-title {
    align-items: center;
    display: block;
    padding: 16px;
    padding-bottom: 0;
    text-transform: uppercase;
  }
  app-seller .card-box-title span {
    font-size: 13px !important;
    font-weight: 500;
  }
  app-seller .card-box-title button {
    position: absolute !important;
    right: 16px;
  }
  app-seller .card-box-body label {
    font-size: 13px;
    font-weight: 400;
  }

  app-seller .card-branch{
    border-radius: 5px;
    background: #fff;
    border: solid 1px #eaeaea;
    padding: 16px;
    margin-bottom: 16px;
  }
  app-seller .card-branch .ant-select{
    min-width: 200px;
  }
  app-seller .card-branch .branch-title{
    font-family: var(--font-heading);
    vertical-align: middle;
  }
  app-seller .card-branch .branch-title .title{
    font-size: 16px;
    font-weight: var(--font-prompt-bold);
  }
  app-seller .card-branch .branch-domain{
    opacity: 0.8;
    padding-left: 22px;
  }
  
  @media (max-width: 767.98px) {
    app-seller .card-branch  .branch-title .title{
      display: block;
      margin-bottom: 5px;
    }
    app-seller .card-branch .branch-domain{
      padding-left: 0;
    }
  }

/* =========================================================================== */





/* =========================================================================== */
/* ================================== PAGES ================================== */
/* =========================================================================== */

  .page-content{
    padding: 50px 0;
  }
  @media (max-width: 767.98px) {
    .page-content{
      padding: 25px 0;
    }
  }
  
  .breadcrumb{
    margin-bottom: 1rem;;
  }

  section.py-5{
    padding-top: 1.5rem!important;
    padding-bottom: 1.5rem!important;
  }

  .widget-product{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .widget-product:after{
    content: '';
    display: block;
    clear: both;
  }
  .widget-product .product-item:after{
    content: '';
    display: block;
    clear: both;
  }
  .widget-product .product-item{
    position: relative;
    padding: 0 .25rem;
  }
  .widget-product .product-item .unavailable-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    z-index: 9;
    border-radius: 4px;
    cursor: not-allowed;
  }
  .widget-product .product-item .product-card{
    position: relative;
  }
  .widget-product .product-item .product-card .unavailable{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
  }
  .widget-product .product-item .product-card .unavailable .unavailable-text{
    position: absolute;
    top: 50%;
    color: #fff;
    font-size: 16px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .widget-product .product-item .product-card .product-cover{
    width: 100%;
    position: relative;
    margin-bottom: .75rem;
    background-color: #eaeaea;
    overflow: hidden;
  }
  .widget-product .product-item .product-cover .inner,
  .widget-product-list .product-item .product-cover .inner{
    position: relative;
    overflow: hidden;
    display: block;
    padding-top: 100%;
    cursor: pointer;
  }
  .widget-product .product-item .product-cover .inner img,
  .widget-product-list .product-item .product-cover .inner img{
    display: block;
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    margin: auto;
    -webkit-transform: translateX(-50%);
    transform:  translateX(-50%);
    transition: all .5s ease;
  }
  .widget-product .product-item .product-cover .inner:hover img{
    opacity: 0.7;
    transition: all .5s ease;
  }

  #halal-overlay {
    width: 60%;
    height: 60%;
    margin-top: 5%;
    margin-left: 17%;
  }

  #detail-halal-overlay {
    position: absolute;
    width: 20%;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 28px;
  }

  #product-halal-overlay {
    width: 35%;
    height: 35%;
    margin-top: 2%;
    margin-left: 30%;
  }

  .widget-product .product-item .product-cover .shop-name{
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 8px;
    right: 6px;
    max-width: 90%;
  }
  .widget-product .product-item .product-cover .shop-name .badge{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 6px;
    background-color: #eaeaea;
    color: #212121;
    font-size: 0.8rem;
    border-radius: 2px;
    opacity: 0.85;
  }
  .widget-product .product-item .product-card .product-title a{
    width: 100%;
    max-height: 46.4px;
    overflow: hidden;
    color: inherit;
    font-size: 0.9rem;
    font-weight: 400;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .widget-product .product-item .product-card .product-title a:hover{
    color: var(--color-darkgreen);
  }
  .widget-product .product-item .product-card .product-price{
    font-size: 1.1rem;
    font-weight: 400;
  }
  .widget-product .product-item .product-card .product-price .price-sell{
    display: inline-block;
    color: var(--color-darkgreen);
    vertical-align: middle;
  }
  .widget-product .product-item .product-card .product-price .price-old{
    display: inline-block;
    font-size: 0.84rem;
    opacity: 0.8;
    margin-left: 5px;
    vertical-align: middle;
  }

  .shop-name-mobile {
    display: none;
  }

  .product-item .product-cover.horizontal .inner{
    padding-top: 75%;
  }
  .product-item .product-cover.vertical .inner{
    padding-top: 150%;
    /* 500*750 */
  }
  .product-item .product-cover.square .inner{
    padding-top: 100%;
  }

  .product-item .product-description{
    text-align: center;
    flex: 1 0 auto;
    overflow: hidden;
    height: 36px;
    line-height: 18px;
    white-space: pre-wrap;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
  }

  .product-item .product-cover.crop-product-image .inner img{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

  @media (min-width: 1200px) {
    app-shop .widget-product .product-item,
    app-product-detail .widget-product .product-item{
        width: 20%;
        margin-bottom: 15px;
    }
    app-shop .widget-product .product-item:nth-child(5n+1),
    app-product-detail .widget-product .product-item:nth-child(5n+1){
        clear: both;
    }
  }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    app-shop .widget-product .product-item,
    app-product-detail .widget-product .product-item{
        width: 25%;
    }
    app-shop .widget-product .product-item:nth-child(4n+1),
    app-product-detail .widget-product .product-item:nth-child(4n+1){
        clear: both;
    }
  }
  @media (max-width: 767.98px) {
    app-shop .widget-product .product-item,
    app-product-detail .widget-product .product-item{
        width: 50%;
        margin-bottom: 1rem;
    }
    app-shop .widget-product .product-item:nth-child(2n+1),
    app-product-detail .widget-product .product-item:nth-child(2n+1){
        clear: both;
    }
  }
  

  .widget-product.food .product-item {
    padding: 10px!important;
    border: 0!important;
    margin: 0!important;
  }
  .widget-product.food .product-item .product-card{
    background-color: #fff;
    padding: 10px;
    border: 1px solid #eaeaea;
  }
  .widget-product.food .product-item .product-card:after{
    content: '';
    display: block;
    clear: both;
  }
  .widget-product.food .product-item .product-card .product-cover{
    width: 100px;
    height: 100px;
    float: left;
    overflow: hidden;
    margin-bottom: 0;
  }
  .widget-product.food .product-item .product-card .product-title,
  .widget-product.food .product-item .product-card .product-price{
    width: calc(100% - 100px);
    float: left;
    padding-left: 10px;
  }
  @media (min-width: 1200px) {
    .widget-product.food .product-item {
      width: 33.333333%!important;
    }
  }
  @media (min-width: 991.98px) and (max-width: 1199.98px) {
    .widget-product.food .product-item {
      width: 50%!important;
      margin-bottom: 0;
    }
  }
  @media (max-width: 991.98px) {
    .section-group{
      margin-bottom: 25px;
    }
    .widget-product.food .product-item {
      width: 100%;
      padding: 7.5px 0;
    }
    .widget-product.food .product-item .product-card .product-cover{
      width: 80px;
      height: 80px;
    }
    .widget-product.food .product-item .product-card .product-title,
    .widget-product.food .product-item .product-card .product-price{
      width: calc(100% - 80px);
    }
  }

  .widget-product-list .product-item{
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #eaeaea;
  }
  app-cart .ant-collapse-content-box .widget-product-list .product-item{
    border-bottom: 0;
  }
  .widget-product-list .product-item:after{
    content: '';
    display: block;
    clear: both;
  }
  .widget-product-list .product-item .product-cover{
    width: 70px;
    float: left;
    background-color: #eaeaea;
  }
  .widget-product-list .product-item .product-detail{
    width: calc(100% - 70px);
    float: left;
    padding-left: .5rem;
  }
  .widget-product-list .product-item .product-detail .product-title{
    font-size: 0.9rem;
    font-weight: 400;
    max-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0.25rem;
  }
  .widget-product-list .product-item .product-detail .product-note{
    margin-bottom: 0.5rem;
  }
  .widget-product-list .product-item .product-detail .product-note .note{
    opacity: 0.7;
    font-size: 0.8rem;
  }


  .widget-aboutshop{
    padding-bottom: 1rem;
    border-bottom: 1px solid #888;
    margin-bottom: 1rem;
  }
  .widget-aboutshop:after{
    content: '';
    display: block;
    clear: both;
  }
  .widget-aboutshop .shop-logo{
    width: 50px;
    height: 50px;
    float: left;
    border: 1px solid #888;
    position: relative;
    overflow: hidden;
  }
  .widget-aboutshop .shop-logo:before{
    content: '';
    display: block;
    position: relative;
    padding-top: 10%;
  }
  .widget-aboutshop .shop-logo img{
    position: absolute;
    display: block;
    width: 100%;
    height: auto;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    top: 0;
  }
  .widget-aboutshop .shop-detail{
    width: calc(100% - 50px);
    float: left;
    font-family: 'Prompt', sans-serif;
    padding: 0;
    padding-left: 0.75rem;
  }
  .widget-aboutshop .shop-detail .shop-title{
    font-weight: 600;
  }
  .widget-aboutshop .shop-type{
    font-size: 12px;
    color: #fff;
    padding: 0.1rem 0.25rem;
    border-radius: 0.25rem;
    font-weight: 400;
  }

  .badge-verified{
    /* background: rgb(34,139,34);
    background: linear-gradient(90deg, #228b22 0%, #117011 100%); */
    background: #FF9800;
    background: linear-gradient(90deg, #FF9800 0%, #FFC107 100%);
    color: #fff;
    border-radius: 4px;
  }
  .badge-unverified{
    background: rgb(213,213,213);
    background: linear-gradient(90deg, #eaeaea 0%, #d5d5d5 100%);
    color: #888;
    border-radius: 4px;
  }

  @media (max-width: 767.98px) {
    .widget-product .product-item{
      margin-bottom: 0.75rem!important;
    }
    .widget-product .product-item .product-card .product-title{
      margin-bottom: 0.25rem!important;
    }

  }

  @media (max-width: 1199.98px) {
    /* PRODUCT CATEGORY FOR MOBILE */
    app-market-product-category .ant-tabs-nav-container-scrolling,
    app-products .ant-tabs-nav-container-scrolling{
      padding-left: 0;
      padding-right: 0;
    }
    app-market-product-category .ant-tabs-nav-scroll,
    app-products .ant-tabs-nav-scroll{
      overflow: scroll;
    }
    app-market-product-category .ant-tabs-nav.ant-tabs-nav-animated,
    app-products .ant-tabs-nav{
      transform: none!important;
    }
    app-market-product-category .ant-tabs-tab-prev, 
    app-market-product-category .ant-tabs-tab-next,
    app-products .ant-tabs-tab-prev, 
    app-products .ant-tabs-tab-next{
      display: none!important;
    }
    app-market-product-category .ant-tabs-nav .ant-tabs-tab,
    app-products .ant-tabs-nav .ant-tabs-tab{
      font-weight: 600;
    }
  }

  @media (max-width: 480px) {

    .shop-name-mobile {
      grid-area: c;
    }

    .widget-product.widget-product-mobile 
    .product-item {
      margin: 0!important;
      padding: 0.375rem 0;
      border-top: 1px solid rgb(217, 217, 217);
      width: 100%;
    }

    .widget-product.widget-product-mobile
    .product-item .product-card {
        display: grid;
        grid-template-areas: 
        'a b b'
        'a c c'
        'a d d';
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin: 0;
        column-gap: 0.625rem;
    }

    .shop-name-mobile {
      display: block;
    }

    .widget-product.widget-product-mobile 
    .product-item .product-cover .shop-name .badge {
      display: none;
    }

    .widget-product.widget-product-mobile 
    .product-item .product-card .product-cover {
      grid-area: a;
      margin: 0;
    }

    .widget-product.widget-product-mobile 
    .product-item .product-card .product-title {
      grid-area: b;
      margin: 0;
      height: fit-content;
      margin: 0;
    }

    .widget-product.widget-product-mobile 
    .product-item .product-card .product-title a {
      height: auto;
    }

    .widget-product.widget-product-mobile 
    .product-item .product-card .product-price {
      grid-area: d;
    }
  }
  
  

  /* =========================================================================== */

.bg-white{
  background-color: #fff;
}
.bg-ci-gd{
  background: var(--ci-gradient);
}
.drawer-reviews{
  width: 90%!important;
  max-width: 480px!important;
}
.drawer-reviews .ant-drawer-body{
  padding-bottom: 24px;
}
.drawer-reviews.pb-0 .ant-drawer-body{
  padding-bottom: 0;
}

.discount .badge {
  display: inline-block;
  position: absolute;
  right: 8px;
  bottom: 38px;
  background-color: #f5222d;
  color: #fff;
  font-size: 12px;
  line-height: 100%;
  font-weight: 700;
  padding: 4px 6px;
  border-radius: 2px;
  z-index: 1;
}